import React, { useState } from 'react';
import akash from '../images/akash.png';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/send-email', { // Make sure this matches your backend
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, subject, message }),
      });
      
      if (response.ok) {
        alert('Message sent successfully!');
        setEmail('');
        setSubject('');
        setMessage('');
      } else {
        alert('Failed to send message. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  return (
    <section className="bg-[#0b080d] min-h-screen flex items-center justify-center">
      <div className="w-full max-w-screen-xl px-4 py-8 lg:py-16">
        <h2 className="mb-12 text-4xl tracking-tight font-extrabold text-center text-white">About Me</h2>
        
        <div className="flex flex-col md:flex-row gap-12 mb-16">
          <div className="md:w-1/2 flex justify-center items-start">
            <div className="relative">
              <img 
                src={akash} 
                alt="Akash" 
                className="w-full max-w-md h-auto aspect-[3/4] object-cover rounded-3xl border-4 border-blue-500 shadow-lg shadow-blue-500/50" 
              />
              <div className="absolute inset-0 rounded-3xl bg-gradient-to-b from-blue-500/20 to-transparent"></div>
            </div>
          </div>
          <div className="md:w-1/2">
            <p className="text-gray-300 text-base leading-relaxed">
              I am an Aerospace Engineering and Math + Computer Science Student at the University of California, San Diego. I also am minoring in Entrepreneurship and Innovation. I am passionate about leveraging new technologies and ideas to promote the expansion and exploration of space and to advance the interests of the engineering community. I am currently involved in multiple organizations at  UCSD and I'm specifically focusing on the intersection of mechanical/software technologies.
              <br /><br />
              For fun I enjoy watching films and reading books to understand how narratives portray and shape society. I am a self taught learner with deep interest in philosophy, astronomy, and a supporter of electric vehicle adoption. As a student I would love to share my interests and opinions with fellow undergraduate students.
              <br /><br />
              Feel free to reach out to me at: <a href="mailto:your.email@example.com" className="text-blue-400 hover:underline">apremkumar@ucsd.edu</a>
            </p>
          </div>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
          <h2 className="mb-12 text-4xl tracking-tight font-extrabold text-center text-white">Let's Talk</h2>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-300">Your email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="shadow-sm bg-gray-700 border border-gray-600 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder=""
              required
            />
          </div>
          <div>
            <label htmlFor="subject" className="block mb-2 text-sm font-medium text-gray-300">Subject</label>
            <input
              type="text"
              id="subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className="block p-3 w-full text-sm text-white bg-gray-700 rounded-lg border border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder=""
              required
            />
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-300">Your message</label>
            <textarea
              id="message"
              rows="6"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="block p-2.5 w-full text-sm text-white bg-gray-700 rounded-lg shadow-sm border border-gray-600 focus:ring-blue-500 focus:border-blue-500"
              placeholder=""
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-blue-600 w-full sm:w-auto hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            Send message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;