import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';  // Correct import path



const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        Akash<span></span>
      </div>
      <ul className="navbar-links">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/projects">Resume</Link></li>
        <li><Link to="/contact">Contact</Link></li>
        <li><Link to="/blog">Blog</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
