import React from 'react';
import { FaGoogleDrive, FaLinkedin, FaLink, FaGithub } from 'react-icons/fa6';
import '../styles/Footer.css';

const XLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor">
    <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
  </svg>
);

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <a href="https://x.com/AkashNPremkumar" target="_blank" rel="noopener noreferrer" className="icon">
          <XLogo />
        </a>
        <a href="https://www.linkedin.com/in/akashpremkumar" target="_blank" rel="noopener noreferrer" className="icon">
          <FaLinkedin />
        </a>
        <a href="https://drive.google.com/drive/u/2/folders/1jXARMkLHXc9p9mh_lD72ujmVxlDZUgZE" target="_blank" rel="noopener noreferrer" className="icon">
          <FaGoogleDrive />
        </a>
        <a href="https://linktr.ee/akashnaren" target="_blank" rel="noopener noreferrer" className="icon">
          <FaLink />
        </a>
        <a href="https://github.com/akashnaren" target="_blank" rel="noopener noreferrer" className="icon">
          <FaGithub />
        </a>
      </div>
      <p className="tech-stack">Made with React, Tailwind CSS, and Vanta</p>
    </footer>
  );
};

export default Footer;