import React, { useEffect, useRef, useState } from 'react';
import '../styles/Home.css';
import * as THREE from 'three';
import GLOBE from 'vanta/dist/vanta.globe.min';


const Home = () => {
  const [vantaEffect, setVantaEffect] = useState(null);
  const myRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        GLOBE({
          el: myRef.current,
          THREE: THREE,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: window.innerHeight,
          minWidth: window.innerWidth,
          scale: 1.00,
          scaleMobile: 1.00,
          color: 0x6900eb,
          backgroundColor: 0xb0a0c
        })
      );
    }

    const handleResize = () => {
      if (vantaEffect) {
        vantaEffect.resize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (vantaEffect) vantaEffect.destroy();
      window.removeEventListener('resize', handleResize);
    };
  }, [vantaEffect]);

  return (
    <div ref={myRef} className="home">
      <div className="content">
        <h1 className="text-7xl text-white font-extrabold mb-4 leading-snug">
          Engineering <br /> <span className="text-blue-500">Hardcore</span>
        </h1>
        <h2 className="text-2xl text-white font-medium">
          MATHEMATICS-COMPUTER SCIENCE + AEROSPACE ENGINEERING
        </h2>
      </div>
    </div>
  );
};

export default Home;