import React, { useState } from 'react';
import heroHinge from '../images/hero_hinge.webp';
import fireCad from '../images/firecad.png';
import balsaCad from '../images/balsa_cad.webp';
import guitarCad from '../images/guitar_cad.webp';
import heroSuspension from '../images/hero_suspension.webp';
import sigmaExploded from '../images/sigma_exploded.png';
import ATT from '../images/ATT.webp';
import dataSci from '../images/data_science.webp';
import MCA from '../images/mcacert.webp';
import FutureHacks from '../images/future_hacks_event_card.webp';
import LifeHacks from '../images/life_hacks_event_card.webp';
import ISEF from '../images/ISEF.webp';
import MCACAD from '../images/mca.png';
import MCADiagram from '../images/diagrammca.png';
import NWPEE from '../images/NWPEE.webp';
import Rocket from '../images/rocket_cad.webp';
import Yale from '../images/yalecert.webp';
import Navigate from '../images/NavigateUCSD.png';
import NWPEEEC from '../images/NWPEEextra.png';
import Whirl from '../images/Whirl.png';
import one from '../images/108.png';
import Regen from '../images/Regen.png';
import actuator from '../images/Actuator.png';
import sole from '../images/solenoid.png';
import daq from '../images/daq.png';
import sdcta from '../images/sdcta.png';
import ysc from '../images/YSC.png';
import tesla from '../images/tesla.webp';
import rtx from '../images/rtx.png';
import nasa from '../images/nasalogo.png';
import tse from '../images/tselogo.png';
import seventh from '../images/seventh_logo.png';
import robot from '../images/trirob.png';
import multiscale from '../images/multiscale_logo.png';
import esw from '../images/esw_logo.png';
import xdhacks from '../images/xdhacks_logo.png';
import seds from '../images/seds.png';

const Projects = () => {
  const [expandedExperience, setExpandedExperience] = useState(null);
  const [expandedProject, setExpandedProject] = useState(null);

  const skills = {
    programmingLanguages: [
      'Java', 'JavaScript', 'React Native', 'TypeScript', 'C', 'C++', 'Robotics Framework', 
      'Python', 'HTML/CSS', 'SQL', 'Perl', 'R', 'ARM Assembly'
    ],
    cadAndSimulation: [
      'Solidworks', 'Fusion 360', 'FreeCAD', 'Simulink', 'AutoCAD', 'Siemens NX'
    ],
    softwareAndTechnologies: [
      'MATLAB', 'Confluence', 'TortoiseSVN', 'Wireshark', 'JIRA', 'Tableau', 'Microsoft Office', 
      'Arduino', 'CAN', 'Git', 'Kubernetes', 'Pandas', 'Linux', 'Windows', 'Raspberry Pi', 'Microsoft Azure'
    ]
  };

  const certifications = [
    { title: 'Computer Science + Mathematics, Aerospace Engineering', issuer: 'University of California, San Diego' },
    { title: 'Minor: Entrepreneurship and Innovation', issuer: 'University of California, San Diego' },
  ];

  const scholarships = [
    { title: 'Diana C. Miles Scholarship', issuer: 'University of California, San Diego' }
  ];

  const experiences = [
    {
      title: 'Engineering Intern',
      company: 'Tesla',
      duration: 'Aug 2024 – present',
      description: 'Position to start soon.',
      link: 'https://www.tesla.com',
      image: tesla
    },
    {
      title: 'Avionics Software Engineering Intern',
      company: 'RTX (Collins Aerospace)',
      duration: 'Jun 2023 – Sep 2023',
      description: 'Developed test suite for AFDX Filter Driver software using Python, Wireshark, Robot Framework.',
      link: 'https://www.rtx.com',
      image: rtx
    },
    {
      title: 'Software/Propulsion Engineer + Business Team',
      company: 'SEDS@UCSD',
      duration: 'Sep 2021 – present',
      description: 'Optimized cooling channels via MATLAB, used Arduino to have motor control systems, and engineered data processing backend. Also Worked on obtaining sponsorships, deliverables, and grants to fund rocket manufacturing, and collaborated with the Halya Team on funding and purchasing parts for engines, structures, and plumbing.',
      link: 'https://www.sedsucsd.org',
      image: seds
    },
    {
      title: 'Developer & Product Manager',
      company: 'Triton Software Engineering',
      duration: 'Sep 2021 – Mar 2023',
      description: 'Developed scaling strategy, contributed to mobile app UI, and created data visualization website for multiple non-profits such as Y Stem & Chess and San Diego County Taxpayers Association.',
      link: 'https://tse.ucsd.edu',
      image: tse
    },
    {
      title: 'Mechanical Engineer',
      company: 'Triton Robotics',
      duration: 'Mar 2021 – Mar 2023',
      description: 'Designed components, repaired electronics, and optimized motor efficiency.',
      link: 'https://tritonrobotics.ucsd.edu',
      image: robot
    },
    {
      title: 'Undergraduate Researcher',
      company: 'Multiscale Flow Physics Laboratory',
      duration: 'Jan 2022 – Present',
      description: 'Worked on optimizing/studying Fire Whirl Behavior via custom made apparatus.',
      link: 'https://asanchez.ucsd.edu/',
      image: multiscale
    },
    {
      title: 'Sensor Research Engineer',
      company: 'NASA Proposal Writing and Evaluation Experience Academy Program',
      duration: 'May 2022 – Aug 2022',
      description: 'Worked on understanding variety of sensors and implementation into the mapping of the environment without Lidar for proposal based on NASA requirements.',
      link: 'https://www.lspace.asu.edu/applications',
      image: nasa
    },
    {
      title: 'Computer Hardware Engineer',
      company: 'NASA Mission Concept Academy Program',
      duration: 'Sep 2022 – Dec 2022',
      description: 'Worked on implementing Communication Data Handling System within Preliminary Design Review and ensured PDR was approved based on NASA Taxonomy',
      link: 'https://www.lspace.asu.edu/applications',
      image: nasa
    },
    {
      title: 'Student Transportation Representative',
      company: 'Seventh College Student Council',
      duration: 'Sep 2021 – Jun 2022',
      description: 'Served as liaison between the Seventh College Student Council and the Student Transportation Advisory Committee, working to initiate programs to improve campus travel for Seventh College students.',
      link: 'https://seventh.ucsd.edu/student-life/Involvement-opportunities/student-council.html',
      image: seventh
    },
    {
      title: 'Information Management Committee & Community Affairs Committee',
      company: 'Engineers for a Sustainable World @ UCSD',
      duration: 'Sep 2021 – Jun 2022',
      description: 'Tracked member involvement, gathered feedback for improvement, and assisted the VP of Information Management in documenting events.',
      link: 'https://eswtritons.wordpress.com/',
      image: esw
    },
    {
      title: 'Executive - Logistics - Outreach Director of XdHacks Mini LA Division',
      company: 'XdHacks Mini LA',
      duration: 'Jun 2018 – Jun 2022',
      description: 'Hosted Life Hacks, securing 10 sponsors and 9 partners, writing the logistics report, moderating workshops, and creating an effective marketing strategy; also hosted Future Hacks, securing 11 sponsors and 11 partners, and helped create an ambassador program.',
      link: 'https://mini.xdhacks.com/la/',
      image: xdhacks
    }
  ];

  const csProjects = [
    { 
      title: 'Fire Whirl Fuel Consumption Programs', 
      description: 'Developed Java program for fire whirl data analysis.',
      details: 'Created program for data input, statistics, and heatmap visualization.',
      link: 'https://github.com/akashnaren/Fire-Whirl-Research',
      image: Whirl
    },
    { 
      title: 'Sensor Analysis and Diagram - Proposal', 
      description: 'Worked on terrain identification through motion pattern recognition.',
      details: 'Developed concept for navigation without visual components.',
      link: 'https://drive.google.com/file/d/14K27CWZ6--VPD4KafsgW-Lm2CraexVv-/view',
      image: NWPEEEC
    },
    { 
      title: 'Communication Data Handling System - Preliminary Design Review', 
      description: 'Contributed to asteroid tracking mission concept.',
      details: 'Researched communication system for transmitting asteroid data.',
      link: 'https://drive.google.com/file/d/1yuL5K4KWOEQkNw3uv4jcXuIYfIhT_VL3/view',
      image: MCACAD
    },
    { 
      title: 'Arduino/C++ Actuator/LED Control', 
      description: 'Manipulating Linear Actuator Control for Thrust/Launch Control',
      details: 'Program that allows control of physical actuator and voltage control of LEDs',
      link: 'https://drive.google.com/drive/folders/15-9Y8LL0RoYy-WZaO7lVnAhvtD2UH-V4?usp=sharing',
      image: actuator
    },
    { 
      title: 'Regenerative Cooling MATLAB Program', 
      description: 'MATLAB Program made to take input parameters to show best optimized engine thermals.',
      details: 'The MATLAB simulation models and analyzes the behavior of a rocket\'s trajectory and performance parameters, providing insights into dynamics, stability, and performance under various conditions to inform design decisions. Using MATLAB for its robust numerical computing and data visualization capabilities, the simulation initializes parameters and constants, defines the equations of motion, employs numerical integration techniques, and iterates over time steps to update the rocket\'s state. The results are then visualized and analyzed to optimize the rocket\'s design and ensure successful deployment, similar to the precision required in RTE systems and TDK for reliable aerospace applications.',
      link: 'https://drive.google.com/drive/folders/15-9Y8LL0RoYy-WZaO7lVnAhvtD2UH-V4?usp=sharing',
      image: Regen
    },
    { 
      title: ' Data Acquisition - Firmware/Web Based', 
      description: 'Acquire data and control mechanical components.',
      details: 'The program uses a web-based GUI via an Arduino, utilizing Python, Flask, HTML, CSS, and JavaScript for serial communication and user interaction. The firmware, written in C++ with the Arduino framework, employs FreeRTOS for task management, handling power monitoring, solenoid control, and CAN communication for real-time operations and feedback.',
      link: 'https://github.com',
      image: sole
    },
    { 
      title: 'Python Data Acquisition', 
      description: 'Data Analysis/Manipulation from ADC chips for real time data monitoring',
      details: 'The project aims to provide a web-based interface to display real-time data from ADC chips connected to an Arduino via serial communication. Using Python, HTML, CSS, and JavaScript, the system reads data from the ADC chips, logs it to a CSV file, and displays it on a web interface for real-time monitoring and visualization. ',
      link: 'https://github.com',
      image: daq
    },
    { 
      title: 'NavigateUCSD', 
      description: 'Implemented mapping functionality for campus navigation.',
      details: 'Used Azure Maps and React for enhanced navigation features.',
      link: 'https://navigateucsd.com/',
      image: Navigate
    },
    { 
      title: 'Flight Delays/Cancellations Analysis', 
      description: 'Analyzed flight data using Python and Pandas.',
      details: 'Distilled complex data into actionable insights.',
      link: 'https://github.com/COGS108/Group134_FA23/blob/master/recent-2.ipynb',
      image: one
    },
    { 
      title: 'Chess Mobile Application', 
      description: 'Aided in complex development and product management with client.',
      details: 'The YSC Mobile App Project aims to develop a mobile application that provides an accessible platform for underserved children to learn chess with mentor assistance. Utilizing Python for the backend, HTML, CSS, and JavaScript for the frontend, and React Native for mobile development, the app offers functionalities like account management, real-time gameplay, mentorship sessions, and lesson progression. The project involves detailed planning, client interaction, team coordination, and continuous improvement to ensure a high-quality, user-friendly experience for students and mentors.',
      link: 'https://github.com/TritonSE/YSC-Mobile-Application',
      image: ysc
    },
    { 
      title: 'Web App utilizing Tableau for San Diego Taxpayers Association', 
      description: 'Aided in complex development and product management with client.',
      details: 'The SDCTA Data Visualization Website Project aims to create an intuitive, interactive platform to enhance user experience and provide accessible statistical information, addressing current limitations. The project involves detailed planning, feature development, and close collaboration with the client and development team to ensure successful implementation and post-launch support. The focus is on robust features like account creation, data visualization, and user feedback to increase engagement and subscription rates.',
      link: 'https://github.com/TritonSE/SDCTA-Data-Visualization-Website',
      image: sdcta
    },
    { 
      title: 'XdHacks Mini Life Hacks', 
      description: 'Acted as Outreach/Logistics Director and helped create Life Hacks Hackathon',
      details: 'With many countries like America falling into a doctor shortage crisis, medical workers now have to work harder than ever to adequately provide treatment to an ever growing population. As the world becomes more and more digitized, virtual assets that aid in lightening the workload to increase the efficiency of their job is a promising solution.',
      link: 'https://mini.xdhacks.com/la/life_hacks',
      image: LifeHacks
    },
    { 
      title: 'XdHacks Mini Future Hacks', 
      description: 'Acted as Executive Director of the Los Angeles Division, created Future Hacks Hackathon',
      details: 'With the 2021 NASA Mars Rover Mission, engineers and scientists have been able to gather more data on Mars, particularly on its environment. As the world races towards the day when the first humans are sent to Mars, transitioning from an Earth-like environment to Mars will be daunting. Virtual assets, however, could be vital in making this daunting process far more efficient, smooth, and manageable.',
      link: 'https://mini.xdhacks.com/la/future_hacks',
      image: FutureHacks
    },
    { 
      title: 'XdHacks Mini ISEF Regeneron', 
      description: 'Aided in hosting event in person',
      details: 'Event allowed me to promote organization, host workshops, activities, and network.',
      link: 'https://mini.xdhacks.com/isef/',
      image: ISEF
    },
  ];

  const mechProjects = [
    { 
      title: 'Hero Robot Design', 
      description: 'Designed suspension and hinge components using Solidworks.',
      link: 'https://drive.google.com/drive/u/2/folders/1oejSdd-C8_Zy3SlFbyjvBD4_2JPbvg-U',
      image: heroHinge
    },
    { 
      title: 'Balsa Plane Design', 
      description: 'Designed plane using Solidworks.',
      link: 'https://drive.google.com/file/d/1sLYrEc3xf4-CYzFRQlrBNHqSdl3OtcDl/view?usp=drive_link',
      image: balsaCad
    },
    { 
      title: 'Guitar CAD Design', 
      description: 'Designed Guitar using Solidworks.',
      link: 'https://drive.google.com/drive/u/2/folders/19r8-ncs2huf93GgdjPnBdkkJxP9u7OAR',
      image: guitarCad
    },
    { 
      title: 'Fire Whirl Apparatus', 
      description: 'Designed experimental plates for fire whirl studies using Solidworks and AUTOCAD.',
      link: 'https://drive.google.com/drive/u/2/folders/1TBw-k0n8ihaZWIJXB0zGc9gJjjk1pG00',
      image: fireCad
    },
    { 
      title: 'Structural Analysis', 
      description: 'Conducted analysis and modeled acceleration profiles for motor optimization.',
      link: 'https://drive.google.com/drive/u/2/folders/1oejSdd-C8_Zy3SlFbyjvBD4_2JPbvg-U',
      image: heroSuspension
    },
    { 
      title: 'Asteroid Tracking Mission', 
      description: 'Contributed to PDR for accurate asteroid trajectory tracking.',
      link: 'https://drive.google.com/file/d/1yuL5K4KWOEQkNw3uv4jcXuIYfIhT_VL3/view',
      image: sigmaExploded
    },
    { 
      title: 'Rocket CAD Design', 
      description: 'Part of MAE 2 Coursework designing an introductory style rocket through CAD.',
      link: 'https://drive.google.com/file/d/1Td4VCpzgpKOtRhGUKJcnsPHpFlgMhWWA/view?usp=sharing',
      image: Rocket
    },
  ];

  const handleDownload = () => {
    const resumeUrl = '../Akashnarendran_Premkumar_Resume.pdf';
    window.open(resumeUrl, '_blank');
  };

  return (
    <div className="min-h-screen bg-[#0b080d] text-white p-8">
      <div className="container mx-auto">
        <h1 className="text-4xl mb-8 text-center">Resume</h1>

        {/* Education Section */}
        <h2 className="text-2xl mb-4">Education</h2>
        <div className="mb-12 flex flex-wrap gap-6">
          {certifications.map((cert, index) => (
            <div key={index} className="bg-gray-800 border border-gray-700 rounded-lg p-6 shadow-lg">
              <h3 className="text-xl font-bold">{cert.title}</h3>
              <h4 className="text-lg text-gray-300">{cert.issuer}</h4>
            </div>
          ))}
        </div>

        {/* Scholarships Section */}
        <h2 className="text-2xl mb-4">Scholarships</h2>
        <div className="mb-12 flex flex-wrap gap-6">
          {scholarships.map((scholarship, index) => (
            <div key={index} className="bg-gray-800 border border-gray-700 rounded-lg p-6 shadow-lg">
              <h3 className="text-xl font-bold">{scholarship.title}</h3>
              <h4 className="text-lg text-gray-300">{scholarship.issuer}</h4>
            </div>
          ))}
        </div>
        
        {/* Skills Section */}
        <h2 className="text-2xl mb-4">Skills</h2>
        <div className="mb-12">
          <h3 className="text-xl mb-2">Programming Languages</h3>
          <div className="flex flex-wrap gap-2 mb-4">
            {skills.programmingLanguages.map((skill, index) => (
              <span key={index} className="bg-gray-700 rounded-full px-3 py-1 text-sm font-semibold">
                {skill}
              </span>
            ))}
          </div>
          
          <h3 className="text-xl mb-2">CAD & Simulation</h3>
          <div className="flex flex-wrap gap-2 mb-4">
            {skills.cadAndSimulation.map((skill, index) => (
              <span key={index} className="bg-gray-700 rounded-full px-3 py-1 text-sm font-semibold">
                {skill}
              </span>
            ))}
          </div>
          
          <h3 className="text-xl mb-2">Software/Technologies</h3>
          <div className="flex flex-wrap gap-2">
            {skills.softwareAndTechnologies.map((skill, index) => (
              <span key={index} className="bg-gray-700 rounded-full px-3 py-1 text-sm font-semibold">
                {skill}
              </span>
            ))}
          </div>
        </div>

        {/* Experience Section */}
        <h2 className="text-2xl mb-4">Professional Experience</h2>
        <p className="text-sm text-gray-400 mb-2">Scroll horizontally and hover to expand</p>
        <div className="mb-12 overflow-x-auto">
          <div className="flex space-x-4 pb-4" style={{ minWidth: 'max-content' }}>
            {experiences.map((exp, index) => (
              <div 
                key={index} 
                className="bg-gray-800 border border-gray-700 rounded-lg p-6 shadow-lg transition-all duration-300 hover:w-96 w-64 group relative"
              >
                <h3 className="text-xl font-bold">{exp.title}</h3>
                <h4 className="text-lg text-gray-300">{exp.company}</h4>
                <p className="text-gray-400 mb-2">{exp.duration}</p>
                <p className="text-gray-300 mt-4 hidden group-hover:block">{exp.description}</p>
                <a href={exp.link} target="_blank" rel="noopener noreferrer" className="mt-2 inline-block">
                  <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
                <div className="absolute bottom-2 right-2 w-10 h-10">
                  <img src={exp.image} alt={`${exp.company} logo`} className="w-full h-full object-contain rounded-lg" />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Computer Science Projects Section */}
        <h2 className="text-2xl mb-4">Computer Science Projects</h2>
        <p className="text-sm text-gray-400 mb-2">Scroll horizontally and hover to expand</p>
        <div className="mb-12 overflow-x-auto">
          <div className="flex space-x-4 pb-4" style={{ minWidth: 'max-content' }}>
            {csProjects.map((project, index) => (
              <div 
                key={index} 
                className="bg-gray-800 border border-gray-700 rounded-lg p-6 shadow-lg transition-all duration-300 hover:w-96 w-64 group"
              >
                <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                <p className="text-gray-300">{project.description}</p>
                <p className="text-gray-300 mt-4 hidden group-hover:block">{project.details}</p>
                <img src={project.image} alt={project.title} className="mt-4 hidden group-hover:block w-full h-auto object-cover rounded-lg" />
                <a href={project.link} target="_blank" rel="noopener noreferrer" className="mt-2 inline-block">
                  <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                  </svg>
                </a>
              </div>
            ))}
          </div>
        </div>

        {/* Mechanical Engineering Projects Section */}
        <h2 className="text-2xl mb-4">Mechanical Engineering Projects</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6">
          {mechProjects.map((project, index) => (
            <div key={index} className="bg-gray-800 border border-gray-700 rounded-lg shadow-lg flex flex-col">
              <div className="h-48 bg-gray-700 rounded-t-lg overflow-hidden">
                <img 
                  src={project.image} 
                  alt={project.title} 
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-4 flex-grow">
                <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                <p className="text-gray-300">{project.description}</p>
              </div>
              <div className="p-4 border-t border-gray-700">
                <a 
                  href={project.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-block text-center"
                >
                  Learn More
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Collage Section */}
        <h2 className="text-2xl mt-12 mb-4">Certificates</h2>
        <div className="grid grid-cols-2 gap-2 mb-12">
          <img src={NWPEE} alt="NWPEE Certificate" className="w-full h-full object-cover rounded-lg"/>
          <img src={MCA} alt="MCA Certificate" className="w-full h-full object-cover rounded-lg"/>
          <img src={Yale} alt="Yale" className="w-full h-full object-cover rounded-lg"/>
          <img src={dataSci} alt="Data Science" className="w-full h-full object-contain rounded-lg"/>
          <img src={ATT} alt="ATT" className="w-full h-full object-cover rounded-lg"/>
        </div>

        {/* Download Resume Button */}
        <div className="flex justify-center mt-12">
          <button 
            onClick={handleDownload}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/>
            </svg>
            <span>Download Resume</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Projects;
