import React, { useState } from 'react';
import AI from '../images/AI.png';
import Tesla from '../images/m3p.webp';
import Startup from '../images/startup.webp';
const blogs = [
  {
    id: 1,
    category: 'AI',
    title: 'NVIDIA: Powering the AI Revolution and Beyond',
    description: 'Explore how NVIDIA has transformed from a gaming graphics company to a key player in the AI revolution.',
    image: AI,
    content: [
      'NVIDIA, once known primarily for its gaming graphics cards, has transformed into a powerhouse driving the artificial intelligence revolution. Founded in 1993, the company has grown from its humble beginnings to become a trillion-dollar tech giant, second only to Microsoft in market capitalization among U.S. public companies.',
      'At the heart of NVIDIA\'s success lies its expertise in graphics processing units (GPUs). Initially developed for rendering superior images in video games, these chips have proven to be exceptionally well-suited for the complex calculations required in AI applications. Unlike traditional central processing units (CPUs), GPUs excel at performing multiple calculations simultaneously, making them more energy-efficient and capable of handling the demanding computational tasks associated with AI.',
      'NVIDIA\'s strategic shift towards AI has paid off handsomely. The company recognized the potential of AI early on, reorienting its operations to capitalize on this emerging technology. This foresight has positioned NVIDIA as a critical player in the development of large language models like ChatGPT, with many tech companies proudly disclosing their use of NVIDIA\'s high-performance A100 chips.',
      'As AI continues to reshape industries and drive innovation, NVIDIA stands at the forefront of this technological revolution. With its comprehensive ecosystem spanning software and hardware, the company is well-positioned to maintain its leadership in accelerated computing and AI. As Jensen Huang puts it, "Generative AI is the defining technology of our time," and NVIDIA is providing the engine to power this new industrial revolution.'
    ],
  },
  {
    id: 2,
    category: 'Automotive',
    title: 'Getting Into Tesla: The Journey to Electric Innovation',
    description: 'Discover the steps Tesla has taken to become a leader in electric vehicles and sustainable energy.',
    image: Tesla,
    content: [
      'Tesla, a company I dreamed of being part of has finally become a reality. I remember the day the offer came and I was bouncing of walls until my excitement died down. I realized that my excitement for Tesla stems from how I grew up alongside the rise of the company. Tesla was founded in 2003 by Martin Eberhard and Marc Tarpenning, has become synonymous with electric vehicles and sustainable energy. Under the leadership of Elon Musk, who joined shortly after its founding, Tesla has revolutionized the automotive industry with its innovative electric cars.',
      'Tesla\'s mission is to accelerate the world\'s transition to sustainable energy. This vision is reflected in its product lineup, which includes electric vehicles like the Model S, Model 3, Model X, and Model Y, as well as energy solutions like solar panels and the Powerwall battery.',
      'The company\'s focus on innovation extends beyond its products. Tesla has developed a network of Supercharger stations to support long-distance travel, and its Autopilot feature is leading the way in autonomous driving technology.',
      'Despite facing numerous challenges, including production delays and financial hurdles, Tesla has emerged as a dominant force in the automotive industry. Its commitment to sustainability and cutting-edge technology continues to drive its success and inspire other companies to follow suit.'
    ],
  },
  {
    id: 3,
    category: 'Startup',
    title: 'Crafting a Vision for Your Startup',
    description: 'Learn how to create compelling vision and mission statements that drive your startup\'s growth and success.',
    image: Startup,
    content: [
      'Creating a vision and mission statement is crucial for any startup. These statements provide direction, inspire employees, and attract investors. A vision statement outlines what your company aspires to become, while a mission statement explains how you plan to achieve that vision.',
      'When crafting these statements, it\'s important to be genuine and reflect the core values and goals of your business. A good vision statement should be broad and long-term, allowing for flexibility as your company grows and evolves.',
      'For example, Tesla\'s vision is "to create the most compelling car company of the 21st century by driving the world’s transition to electric vehicles." This statement is both ambitious and inspirational, setting a clear direction for the company.',
      'Your mission statement should be concise and actionable, focusing on the day-to-day operations that will help you achieve your vision. For instance, Tesla\'s mission is "to accelerate the world’s transition to sustainable energy." This mission drives all of Tesla\'s business decisions and strategies.'
    ],
  },
];

const Blog = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);

  const openArticle = (blog) => {
    setSelectedBlog(blog);
  };

  const closeArticle = () => {
    setSelectedBlog(null);
  };

  if (selectedBlog) {
    return (
      <div className="min-h-screen bg-[#0b080d] text-white">
        <div className="container mx-auto px-4 py-8">
          <button 
            onClick={closeArticle}
            className="mb-4 text-primary-600 font-semibold hover:text-primary-700"
          >
            ← Back to Blog List
          </button>
          <article className="max-w-2xl mx-auto">
            <h1 className="text-3xl font-bold mb-4">{selectedBlog.title}</h1>
            <img className="w-full rounded-lg mb-4" src={selectedBlog.image} alt={selectedBlog.title} />
            <p className="text-sm text-gray-400 mb-4">{selectedBlog.category}</p>
            {selectedBlog.content.map((paragraph, index) => (
              <p key={index} className="text-lg leading-relaxed mb-4">{paragraph}</p>
            ))}
          </article>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0b080d]">
      <section className="py-8 lg:py-16">
        <div className="container mx-auto px-4">
          <div className="grid gap-8 lg:grid-cols-4 sm:grid-cols-2">
            {blogs.map((blog) => (
              <div key={blog.id} className="max-w-sm bg-white rounded-lg overflow-hidden shadow-lg">
                <img className="w-full" src={blog.image} alt={blog.title} />
                <div className="p-6">
                  <h5 className="text-gray-900 font-semibold text-sm">{blog.category}</h5>
                  <h3 className="text-xl font-bold mb-2">{blog.title}</h3>
                  <p className="text-gray-700 text-base mb-4">{blog.description}</p>
                  <button 
                    onClick={() => openArticle(blog)} 
                    className="text-primary-600 font-semibold hover:text-primary-700"
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;